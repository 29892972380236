<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-start self-center w-11/12 mt-8 bg-yellow p-4">
      <v-data-table
        :headers="headers"
        :items="challenges"
        :items-per-page="10"
        class="w-full"
        :loading="loadingData"
        loading-text="Cargando retos"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ new Date(item.createdAt).toLocaleDateString() }}
        </template>

        <template v-slot:[`item.imgUrl`]="{ item }">
          <a
            :href="item.imgUrl"
            target="_blank"
          >Ver</a>
        </template>

        <template v-slot:[`item.accept`]="{ item }">
          <button
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="setChallengePoint(item)"
          >Aceptar</button>
        </template>

        <template v-slot:[`item.reject`]="{ item }">
          <button
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="rejectChallengePoint(item)"
          >Rechazar</button>
        </template>
      </v-data-table>
    </div>
    <Loader :isLoading="loadingData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      challenges: [],
      headers: [
        {
          text: "Id del reto",
          align: "start",
          value: "challengeId",
        },
        { text: "Puntos", value: "points" },
        { text: "Fecha", value: "createdAt" },
        { text: "Ver imagen", value: "imgUrl" },
        { text: "Usuario", value: "uid" },
        { text: "Aceptar", value: "accept" },
        { text: "Rechazar", value: "reject" },
      ],
      loadingData: true,
      currentPeriod: null,
    };
  },
  async mounted() {
    const res = await this["challenges/getChallenges"]();
    if (res.success) {
      this.challenges = res.challenges;
    }

    const res6 = await this["user/getCurrentPeriod"]();
    if (res6.success) {
      this.currentPeriod = res6.currentPeriod;
    }
    this.loadingData = false;
    this.isLoading = false;
  },

  methods: {
    ...mapActions([
      "challenges/getChallenges",
      "user/setChallengePoint",
      "user/rejectChallengePoint",
      "user/getCurrentPeriod",
    ]),

    async setChallengePoint(challenge) {
      console.log(challenge);
      this.isLoading = true;
      const obj = {
        category: `Reto ${challenge.challengeId}`,
        companyId: challenge.companyId,
        points: challenge.points,
        period: this.currentPeriod,
        ref: `Reto ${challenge.challengeId}`,
        timeStamp: Date.now(),
        uid: challenge.uid,
      };

      await this["user/setChallengePoint"]({ obj, challenge });

      const index = this.challenges.indexOf(challenge);
      this.challenges.splice(index, 1);

      this.isLoading = false;
    },

    async rejectChallengePoint(challenge) {
      this.isLoading = true;
      await this["user/rejectChallengePoint"](challenge.id);
      const index = this.challenges.indexOf(challenge);
      this.challenges.splice(index, 1);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>